import React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Todo from '../components/todo';

function About({ data, location }) {
  const siteTitle = data.site.siteMetadata.title;
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Contact ChordSL.com" />
      <Todo />
    </Layout>
  );
}

export default About;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
